  <!--
  * @Date: 2024-08-09 11:05:55
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 17:37:37
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\selectPoint.vue
  -->
  <template>
    <div class="community-selector">
      <!-- <van-nav-bar title="选择附近放映点" left-arrow @click-left="back" /> -->
      <div class="community-selector-address">
        <div>
          <span>您当前的位置</span>
          <!-- <van-button
            size="small"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            @click="autoGps"
          >
            测试点击
          </van-button> -->
        </div>
        <div>
          <span class="community-selector-address-btn" @click="showArea = true">
            更改地址
            <van-icon name="aim" size="16" />
          </span>
        </div>
      </div>
      <div class="community-selector-title">
        <van-icon name="location" size="20" color="#1492FF" />
        {{ gpsAddress || '未定位' }}
        <van-button plain v-if="!gpsAddress" @click="autoGps" round size="small">
          重新获取定位
        </van-button>
      </div>
      <!-- 放映点列表 -->
      <div class="community-selector-content">
        <div
          class="community-selector-list"
          v-for="(item, index) in showList"
          :key="index"
          @click="handleSelectPoint(item)"
        >
          <van-cell-group>
            <van-cell
              center
              :class="{
                'community-selector-list-active': item.id === selectedPointId,
              }"
              :title="`${index + 1}.${item.name} ${
                item.meter ? `(${distanceToKm(item.meter)})` : ''
              }`"
              size="large"
              :label="item.address"
            >
              <template #extra>
                <!-- 方形图标 -->
                <van-icon
                  name="certificate"
                  v-if="item.id === selectedPointId"
                  color="#1989fa"
                  size="30"
                />
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
      <div class="community-selector-btn" v-if="showList.length > 0">
        <van-button type="info" round block @click="confirmSelect"
          >确定选择</van-button
        >
      </div>
      <div v-else>
        <van-empty
          class="custom-image"
          image="https://img.dev.zhijiangfilm.com/i/2024/10/08/6704a96f6c8ee.png"
          description="暂未找到符合条件的放映点，请重新选择地址"
        />
      </div>
      <van-popup v-model="showArea" position="bottom">
        <div>
          <van-cascader
            v-model="communityCode"
            title="请选择所在社区"
            :close-on-click-overlay="false"
            round
            active-color="#1989fa"
            :options="options"
            @change="addressChange"
            @close="closeArea"
            @finish="onFinish"
          />
        </div>
      </van-popup>
    </div>
  </template>

  <script>
  import { Dialog, Toast, Notify } from 'vant'
  import { api } from '@/api/index'
  import { SetPvAndUv } from '@/mixins/PvAndUv'

  export default {
    name: 'communitySelector',
    mixins: [SetPvAndUv],
    props: {
      communityName: {
        type: String,
        default: '请选择放映点',
      },
    },
    data() {
      return {
        cascaderValue: '',
        show: false,
        // 显示地域选择
        showArea: false,
        options: [],
        // 省市区县乡镇村地址
        gpsAddress: '',
        isCityLevel: true,
        levels1: [], // 中国
        levels2: [], // 省
        levels3: [], // 市/省直辖市
        levels4: [], // 区/县
        levels5: [], // 乡镇/街道
        levels6: [], // 村/社区

        // 选中的社区Code
        communityCode: null,
        communityInfo: null,

        // 放映点列表数据
        showList: [],

        // 选中的放映点
        selectedPoint: null,
        selectedPointId: null,
        queryPoint:{
          areaId: null,
          lng: 0,
          lat: 0,
        }
      }
    },
    mounted() {
      this.autoGps()
      console.log({ ZWJSBridge })
      this.loadArea(3)
    },
    methods: {
      // 加载地域信息
      loadArea(levelIndex) {
        // 一级 二级 不用加载
        console.log('加载区域')
        switch (levelIndex) {
          case 1:
            console.log('国家', value)
            break
          case 2:
            console.log('省', t)
            break
          case 3:
            if (this.levels3.length === 0) {
              this.getCityList()
            }
            break

          case 4:
            console.log('区', t)
            break

          case 5:
            console.log('乡镇', t)
            break
          case 6:
            console.log('村', t)
            break
          default:
            break
        }
      },
      back() {
        this.$router.back()
      },

      //获取城市列表
      getCityList() {
        if (this.levels1.length > 0) return
        api.getCityList().then((res) => {
          // 判断是否是市级列表
          if (res[0].level === 3) {
            this.isCityLevel = true
          } else if (res[0].level === 4) {
            this.isCityLevel = false
          }
          res.map((ct) => {
            this.options.push({
              text: ct.name,
              value: ct.id,
              children: [],
            })
          })
        })
      },
      //获取子区域地点
      async getChildArea(level, id) {
        return new Promise((resolve, reject) => {
          api
            .getChildList({ id: id })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      // 选择地域
      addressChange(value) {
        // var levelIndex = this.isCityLevel ? 3 : 2;
        // 起始级别
        const startLevel = 2 // 代表市级
        const levelIndex = value.tabIndex + startLevel + 1
        // console.log({ value })
        // 如果是第4层不用请求子区域
        if (value.tabIndex === 6) {
          return
        }
        if (levelIndex === 6) {
          // alert('请选择了全部');
          // this.onFinish(value)
          return
        }
        this.getChildArea(levelIndex, value.value).then((t) => {
          console.log({ t, value, levelIndex: levelIndex })
          // this['levels' + 1] = []
          this['levels' + levelIndex] = []
          t.map((i) => {
            // 判断如果是乡镇，则需要添加村的选项，不在增加村级别子项children
            if (levelIndex < 5) {
              this['levels' + levelIndex].push({
                text: i.name,
                value: i.id,
                checkStrictly: true,
                children: [],
              })
            } else {
              this['levels' + levelIndex].push({
                text: i.name,
                value: i.id,
              })
            }
          })
          value.selectedOptions[value.tabIndex].children =
            this['levels' + levelIndex]
        })
      },
      // 选择完成
      onFinish({ selectedOptions }) {
        // console.log('onFinish', selectedOptions)
        const value = selectedOptions.map((r) => {return {text: r.text, value: r.value}})
        console.log('onFinish', value)
        this.$store.commit('changeUserAddress', value)
        this.communityInfo = selectedOptions
        this.gpsAddress = selectedOptions.map((r) => r.text).join(' ')
        this.showArea = false
        // this.selectedCommunity = value
        this.queryPoint.areaId = this.communityCode,
        this.queryPoint.lng = 0,
        this.queryPoint.lat = 0,
        this.getCommunityList()
      },
      // 手动选择社区、村
      selectArea() {
        console.log('手动选择社区、村')
        this.getCityList()
      },
      // 自动获取gps
      autoGps() {
        // 判断是否支持ZWJSBridge.getLocation定位

        return ZWJSBridge.getLocation()
          .then((result) => {
            // this.$store.commit("changeUserAddress", [result.city, result.region]);
            // this.$store.commit("changeUserAddressLocation", result);
            // this.selectArea.text = this.getLastItem(this.$store.state.userAddress);
            // console.log("this.selectArea2", this.selectArea);
            this.gpsAddress = result.detailAddress
            this.queryPoint.lng = result.longitude
            this.queryPoint.lat = result.latitude
            this.queryPoint.areaId = null
            this.getCommunityList()
          })
          .catch((error) => {
            console.log(error)
            Dialog.confirm({
              title: '此服务需要获取您的位置信息',
              message: '为您推荐最近的放映点及影片信息',
              confirmButtonColor: '#1492FF',
              confirmButtonText: '打开定位',
              cancelButtonText: '手动选择地址',
              showCancelButton: true,
            })
              .then(() => {
                // 提示用户打开定位
                Toast('请检查系统设置是否允许定位')
              })
              .catch(() => {
                this.showArea = true
              })
          })
        // this.setUserLocation();
        // this.show = false;
      },
      // 获取放映点列表
      async getCommunityList() {
        // 循环10次生成模拟10个放映点数据
        this.showList = []
        const res = await api
          .getShowPointList(this.queryPoint)
          .then((result) => {
            console.log(result)
            const { surrounds } = result
            // console.log({surrounds});

            this.showList = surrounds.map((item, index) => item)
          })
          .catch((err) => {
            console.log(err)
          })

        // for (let i = 0; i < 10; i++) {
        //   this.showList.push({
        //     name: this.communityInfo[3].text + '放映点' + i,
        //     id: i,
        //     address:
        //       this.communityInfo.map((r) => r.text).join(' ') + '放映点' + i,
        //   })
        // }

        console.log('获取放映点列表', this.showList)
      },
      closeArea() {
        // 如果没有地址说明没有定位到或者用户没有手动选择地址
        console.log(this.gpsAddress)

        if (this.gpsAddress) {
          this.showArea = false
          return
        }
      },
      // 选中放映点
      handleSelectPoint(item) {
        console.log('选中放映点', item)
        if (this.selectedPointId === item.id) {
          this.selectedPointId = null
          this.selectedPoint = null
          return
        } else {
          this.selectedPoint = item
          this.selectedPointId = item.id
        }
      },
      // 确认选择社区
      confirmSelect() {
        if (this.selectedPoint) {
          // 跳转到之前
          const { form, back } = this.$route.query
          console.log({ form, back })

          if (form) {
            this.saveUserPoint(this.selectedPoint)
            if (back) {
              this.$router.go(-1)
            } else {
              this.$router.push({
                path: form,
                query: {
                  communityId: this.selectedPoint.id,
                },
              })
            }
          } else {
            this.$router.push({
              name: 'ruralFilm',
              query: {
                communityId: this.selectedPoint.id,
                communityName: this.selectedPoint.name,
              },
            })
          }
        } else {
          Toast('请选择放映点')
        }
      },
      // 保存放映点
      saveUserPoint(point) {
        console.log({ point })
        if (point) {
          this.$store.dispatch('changeuserPoint', point)
          this.userPoint = point
        } else {
          Toast('请选择放映点')
          this.$refs.refCommunitySelector.showCommunitySelector()
        }
      },
      // 距离米转化为距离公里
      distanceToKm(distance) {
        return (distance / 1000).toFixed(2) + '公里'
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.autoGps()
        }
      },
      showArea(val) {
        console.log(val)
        if (!val && !this.communityInfo) {
          setTimeout(() => {
            this.showArea = true
          }, 300)
        }
      },
    },
  }
  </script>

  <style scoped lang="less">
  .custom-image .van-empty__image {
    width: 90px;
    height: 90px;
  }
  .community-selector {
    padding: 4px;
    font-size: @font-size-base;
    color: @text-color-primary;
    background: @bg-color-base;

    &-title {
      font-size: @font-size-medium;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 12px;
    }
    &-list {
      margin: 12px 12px 0 12px;
      &-active {
        border: 1px solid #1492ff;
        border-radius: 4px;
        transition: all 0.5s ease;
        transform: scale(1.01);
      }
    }

    &-content {
      overflow-y: scroll;
      max-height: 80vh;
    }

    &-address {
      font-size: 14px;
      display: flex;
      padding: 0 12px;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      font-weight: bold;

      &-btn {
        color: @color-primary;
      }
    }
    &-btn {
      position: fixed;
      bottom: 5vh;
      left: 30vw;
      width: 40vw;
    }
  }

  ::v-deep .van-cascader__options {
    height: calc(65vh);
    transform: translateY(-10px);
  }
  </style>
